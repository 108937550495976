// extracted by mini-css-extract-plugin
export var root = "map-module--root--7cE8U";
export var nodes = "map-module--nodes--GE4G5";
export var inner = "map-module--inner--6xn6I";
export var projectWrapper = "map-module--projectWrapper--vaCB1";
export var mapWrapper = "map-module--mapWrapper--kkdKn";
export var active = "map-module--active--KpbnW";
export var labels = "map-module--labels--eY1Kx";
export var dash = "map-module--dash--x6oJd";
export var projectInner = "map-module--projectInner--eunAq";
export var imgWrapper = "map-module--imgWrapper--aDZLF";
export var projectsInner = "map-module--projectsInner--uQhH7";
export var partners = "map-module--partners--4BwXj";
export var arrowButtonLeft = "map-module--arrowButtonLeft--TfS8X";
export var arrowButtonRight = "map-module--arrowButtonRight--uVj0T";